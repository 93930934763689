import { extend } from 'vee-validate'
import { required, max, email, numeric, confirmed } from 'vee-validate/dist/rules'

// Add the required rule
extend('required', {
  ...required,
  message: '请输入{_field_}。'
})

extend('select', {
  ...required,
  message: '请选择{_field_}。'
})

extend('phone', {
  validate: value => value.length === 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value)
})

extend('max', {
  ...max,
  message: '{_field_}不能超过{length}位'
})

extend('email', {
  ...email,
  message: '邮箱格式不正确'
})
extend('numeric', {
  ...numeric,
  message: '请输入数字'
})
extend('confirmed', {
  ...confirmed,
  message: '{_field_}输入不一致'
})
