import Vue from 'vue'
import Vuex from 'vuex'
import storage from '../utils/storage'
import getters from './getters'
import user from './modules/user'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // eslint-disable-next-line no-undef
    show: storage.get('show'),
    title: storage.get('title') || '',

    msgPool: [], // 弹出消息队列

    scrollIndex: '0-0',
  },
  mutations: {
    message(state, step) {
      state.msgPool.push(step)
      //将消息添加到消息列表
      setTimeout(() => {
        state.msgPool.shift()
      }, step.time)
      //清除消息
    },
    intersect(state, index) {
      state.scrollIndex = index
    },
  },
  actions: {},
  modules: {
    user
  },
  getters
})
export default store
