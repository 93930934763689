import axios from 'axios'
import router from '@/router'
import { getToken } from '@/utils/auth'
import qs from 'qs'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  //  baseURL: 'http://192.168.3.18:8081/api/yiyou/',
  baseURL:window.location.href.substring(0,window.location.href.indexOf('/')) + '/back/api/yiyou/',
  // baseURL: 'http://test-bim-api.ctsource.cn/bim-back/',
  timeout: 10000
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }

    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    // if (response.config.responseType === 'arraybuffer') return response.data
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.returnStatus && res.returnStatus !== '0') {
      // message.error(res.message || '处理失败')
      return false
    } else {
      if (response.config.method !== 'post') {
        // message.success(res.message || '处理成功')
      }
      return res
    }
  },
  (error) => {
    // message.error(error.message || '处理异常')
    if (error.response.data.status === '401') {
      router.push({ path: '/login' })
    } else {
      return Promise.reject(error.response)
    }
  }
)

export default service
