import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import * as echarts from 'echarts'
import router from './router'
import store from './store'
import storage from './utils/storage'
import axios from 'axios'
import './utils/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueClipboard from 'vue-clipboard2'


import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.component('vv-provider', ValidationProvider)
Vue.component('vv-observer', ValidationObserver)
Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  storage,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
import Message from './components/Message'
Vue.prototype.$Message = Message
Vue.prototype.$echarts = echarts
Vue.prototype.$ajax = axios
