<template>
  <v-app style="font-family: Triz">
    <v-main>
      <router-view :key="key"> </router-view>
    </v-main>
    <Message />
  </v-app>
</template>

<script>
import Message from './components/Message.vue'

export default {
  name: 'App',
  components: {
    Message: Message,
  },
  data() {
    return {}
  },
  computed: {
    key() {
      return this.$route.path + Math.random()
    },
  },
  methods: {},
}
</script>