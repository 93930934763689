import request from '@/utils/request'

//获取权限接口
export function getRoleMy() {
  return request({
    url: 'role/my',
    method: 'get'
  })
}

export function login(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data
  })
}

//摄像头数量
export function cameraCountNumber() {
  return request({
    url: 'camera/cameraCount',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: 'user/logout',
    method: 'post'
  })
}

//获取摄像头列表
export function cameraList(data) {
  return request({
    url: 'camera',
    method: 'get',
    params: data
  })
}

//获取摄像头列表
export function cameraIssueList(data) {
  return request({
    url: 'image/list',
    method: 'get',
    params: data
  })
}

//获取摄像头列表
export function cameraListByAreaId(data, id) {
  return request({
    url: `camera/area/${id}`,
    method: 'get',
    params: data
  })
}

//删除摄像头
export function deleteCamera(data) {
  return request({
    url: 'camera/delete',
    method: 'delete',
    data
  })
}

//删除摄像头
export function addCamera(data) {
  return request({
    url: 'camera/add',
    method: 'post',
    data
  })
}

//修改摄像头
export function editCamera(data) {
  return request({
    url: 'camera/modify',
    method: 'put',
    data
  })
}

//获取员工列表
export function getUser(data) {
  return request({
    url: 'user/list?page=' + data.page + '&size=' + data.size,
    method: 'get',
    data
  })
}

//新增员工列表
export function addUser(data) {
  return request({
    url: 'auth/register',
    method: 'post',
    data
  })
}

//删除员工列表
export function deleteUser(data) {
  return request({
    url: 'user/delete',
    method: 'delete',
    data
  })
}

//删除员工列表
export function editUser(data) {
  return request({
    url: 'role/updateRole',
    method: 'put',
    data
  })
}


//员工权限列表
export function getUserRole() {
  return request({
    url: 'role/queryRole',
    method: 'get'
  })
}

//获取chart图
export function getAreaCameraCount() {
  return request({
    url: 'camera/areaCameraCount',
    method: 'get'
  })
}

//获取地图位置
export function getQueryCameraCountGroup() {
  return request({
    url: 'camera/queryCameraCountGroup',
    method: 'get'
  })
}


//处理图片
export function updateImage(data) {
  return request({
    url: 'image/updateImage',
    method: 'put',
    data
  })
}

export function getCameraAbnormal() {
  return request({
    url: 'camera/cameraAbnormal',
    method: 'get'
  })
}

export function getQueryDetectView(data) {
  return request({
    url: 'detect/queryDetectView?page=0&size=30',
    method: 'post',
    data
  })
}

export function getImageCamera(data) {
  return request({
    url: 'image/list?page=0&size=21',
    method: 'get',
    data
  })
}

export function getQueryImage(data) {
  return request({
    url: 'image/queryImage',
    method: 'post',
    data
  })
}

//地域列表
export function getAreaRole() {
  return request({
    url: 'area/all',
    method: 'get'
  })
}
//地域修改保存
export function editArea(data) {
  return request({
    url: 'area/editArea',
    method: 'put',
    data
  })
}
//删除地域
export function delectArea(data) {
  return request({
    url: 'area/delete',
    method: 'delete',
    data
  })
}

