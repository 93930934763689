import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import vuetify from '@/plugins/vuetify'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
})

export default new Vuetify()

//导入通用样式
import '../components/Tree/src/styles/index.scss'
import ZmTreeOrg from '../components/Tree/src/index'
Vue.use(ZmTreeOrg)
