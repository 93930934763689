<template>
  <div>
    <template v-for="(item, index) in $store.state.msgPool">
      <v-slide-x-reverse-transition :key="index" mode="out-in">
        <v-alert
          :key="index"
          :value="!!snackbar.type"
          border="left"
          :type="snackbar.type"
          transition="slide-x-reverse-transition"
          class="float-right"
        >
          {{ snackbar.message }}
        </v-alert>
      </v-slide-x-reverse-transition>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    snackbar() {
      return this.$store.state.msgPool.length ? this.$store.state.msgPool[0] : {}
    },
  },
  methods: {},
}
</script>
<style>
.message {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9999;

  min-width: 300px;

  display: flex;
  flex-direction: column;
}
</style>
