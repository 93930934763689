import Cookies from 'js-cookie'

const TokenKey = 'X-Litemall-Admin-Token'
const password = 'Password'
const username = 'Username'
const checkboxif = 'Checkbox'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getPaw() {
  return Cookies.get(password)
}

export function setPaw(psw) {
  return Cookies.set(password, psw)
}

export function removePaw() {
  return Cookies.remove(password)
}

export function getUser() {
  return Cookies.get(username)
}

export function setUser(user) {
  return Cookies.set(username, user)
}

export function removeUser() {
  return Cookies.remove(username)
}
export function getCheckbox() {
  return Cookies.get(checkboxif)
}

export function setCheckbox(checkbox) {
  return Cookies.set(checkboxif, checkbox)
}

export function removeCheckbox() {
  return Cookies.remove(checkboxif)
}
