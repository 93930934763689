import { login, logout } from '../../api/account'
import {
  getToken,
  setToken,
  removeToken,
  setPaw,
  removePaw,
  setUser,
  removeUser,
  setCheckbox,
  removeCheckbox
} from '@/utils/auth'

const user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    organizationId: '',
    roles: [],
    perms: [],
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ORGANID: (state, organizationId) => {
      state.organizationId = organizationId
    },
    SET_PERMS: (state, perms) => {
      state.perms = perms
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername({ commit }, userinfo) {
      return new Promise((resolve, reject) => {
        login(userinfo).then(response => {
          const token = response.data.accessToken
          commit('SET_TOKEN', token)
          setToken(token)
          if (userinfo.checkbox) {
            setPaw(userinfo.password)
            setUser(userinfo.userName)
            setCheckbox(true)
          } else {
            removeUser()
            removePaw()
            removeCheckbox()
          }
		  sessionStorage.setItem('userDetails',JSON.stringify(response.data.userDetails)); // 存入登入人员信息
          resolve(response.data)
        }).catch(error => {
          reject(error)

        })
      })
    },
    Organization({ commit }, organizationId) {
      commit('SET_ORGANID', organizationId)
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMS', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    // 动态修改权限
    ChangeRoles({ commit, dispatch }, role) {
      return new Promise(resolve => {
        commit('SET_TOKEN', role)
        setToken(role)
        getUserInfo(role).then(response => {
          const data = response.data
          commit('SET_ROLES', data.roles)
          commit('SET_PERMS', data.perms)
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          commit('SET_INTRODUCTION', data.introduction)
          dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
          resolve()
        })
      })
    }
  }
}

export default user
