import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login/login'),
    hidden: true,
  },
  {
    path: '/home',
    component:  () => import('@/views/home/index'),
  },
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    console.log('scrollBehavior', to, from, savedPosition)
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes,
  mode: '',
})

export default router
